import { useEffect, useMemo, useState } from "react";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import type { Logger } from "@expert/logging";
import { type Group } from "./types";

export interface ContextProps {
    appName: "expert-assist-standalone" | "expert-workspace";
    employeeId: string;
    environment: string;
    lob?: string;
    partner: string;
    source: "expert-assist" | "expert-ui";
}

export interface FeatureFlagProps<T extends Record<string, unknown>> {
    analyticGroup: Group;
    context: ContextProps;
    features: T;
    logger: Logger;
}

export function useFlags<T extends Record<string, unknown>>({
    analyticGroup: group,
    context,
    features,
    logger: loggerProp,
}: FeatureFlagProps<T>) {
    const [updatedFeatures, setUpdatedFeatures] = useState(features);
    const logger = useMemo(() => loggerProp.child({ module: "useFlags" }), [loggerProp]);

    const { dispatcher } = useReactAnalytics();

    useEffect(() => {
        const { employeeId, partner } = context;

        if (!employeeId || !partner) {
            logger.info({ context }, "Feature Flags | missing context for fetching flags");
            return;
        }

        const fetchFeatures = async () => {
            const featureKeys = Object.keys(features);

            logger.info({ context, featureKeys }, "Feature Flags | fetching flags");

            const promises = featureKeys.map(async (feature) => {
                const data = await getFeature(feature, { ...context });
                return { [feature]: data };
            });

            try {
                const results = await Promise.all(promises);

                let resultsObject = { ...features };

                results.forEach((result) => {
                    // don't overwrite features if data value comes back undefined from barvidently
                    if (Object.values(result)[0] !== undefined) {
                        resultsObject = { ...resultsObject, ...result };
                    }
                });

                setUpdatedFeatures(resultsObject);

                logger.info({ context, features: resultsObject }, "Feature Flags | fetched flags");

                void dispatcher.dispatchBusinessEvent("FeatureFlagResults", {
                    ...context,
                    features: resultsObject,
                    group,
                });
            } catch (error: unknown) {
                setUpdatedFeatures({ ...features });
                logger.error({ context, error }, "Feature Flags | error fetching flags");
            }
        };

        void fetchFeatures();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, features]);

    return updatedFeatures;
}
