import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { SubscriptionStatus } from "./websocket/types";

export interface GaiaStore {
    isWsAuthenticated: boolean;
    setIsWsAuthenticated: (isWsAuthenticated: boolean) => void;

    subscriptionStatus: Record<string, SubscriptionStatus>;
    setSubscriptionStatus: (sessionId: string, subscriptionStatus: SubscriptionStatus) => void;
}

export const useGaiaStore = create<GaiaStore>()(
    subscribeWithSelector(
        immer(
            devtools(
                (set) => ({
                    isWsAuthenticated: false,
                    setIsWsAuthenticated: (isWsAuthenticated: boolean) => {
                        set(
                            (state) => {
                                state.isWsAuthenticated = isWsAuthenticated;
                            },
                            false,
                            "setIsWsAuthenticated",
                        );
                    },
                    subscriptionStatus: {},
                    setSubscriptionStatus: (sessionId: string, subscriptionStatus: SubscriptionStatus) => {
                        set(
                            (state) => {
                                state.subscriptionStatus[sessionId] = subscriptionStatus;
                            },
                            false,
                            "setSubscriptionStatus",
                        );
                    },
                }),
                {
                    enabled: import.meta.env.MODE !== "production",
                    store: "gaia",
                    name: "expert/gaia",
                },
            ),
        ),
    ),
);
