import { Stack, Text, Textarea } from "@mantine/core";
import { useFeedbackDraftStore } from "../../store";
import classes from "../../styles.module.css";
import { type FreeTextCategory, feedbackUIMappingFreeText } from "../../types";
import { FeedbackRadioGroup } from "./FeedbackRadioGroup";

function getPlaceholder(category?: string) {
    const freeTextCategory = category as FreeTextCategory | undefined;
    const placeholder = feedbackUIMappingFreeText.default.placeholder;
    return freeTextCategory ? placeholder[freeTextCategory] : placeholder["General feedback"];
}

export function FreeTextFeedback({ isLoading }: { isLoading: boolean }) {
    const { draft, setDraft } = useFeedbackDraftStore();
    const { subtitle, categories, bottomText } = feedbackUIMappingFreeText.default;

    const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDraft({
            ...draft,
            description: event.currentTarget.value,
        });
    };

    return (
        <Stack>
            <Text size="sm" c="dark.3" mb="lg">
                {subtitle}
            </Text>
            <FeedbackRadioGroup categories={categories} direction="row" />
            <Textarea
                classNames={{ input: classes.input }}
                value={draft.description}
                minRows={7.5}
                maxRows={7.5}
                mt="lg"
                autosize
                onChange={onChangeText}
                placeholder={getPlaceholder(draft.category)}
                data-testid="feedback-textarea"
                disabled={isLoading}
            />
            <Text size="xs" c="dark.3" mt="xs" mb="xs">
                {bottomText}
            </Text>
        </Stack>
    );
}
