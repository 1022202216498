import { createContext, useContext } from "react";
import type { Logger } from "@expert/logging";
import { useGaiaWebsocketInternal } from "./hooks/useGaiaWebsocket";
import type { GaiaWebsocketContext } from "./types";

export const GaiaContext = createContext<GaiaWebsocketContext | null>(null);
interface GaiaWebsocketProviderProps {
    url: string;
    application: "expert-workspace" | "expert-assist";
    token: string;
    identity: string;
    logger: Logger;
    children?: React.ReactNode;
}

export function GaiaWebsocketProvider({
    url,
    application,
    token,
    identity,
    children,
    logger,
}: GaiaWebsocketProviderProps) {
    const webSocketObject = useGaiaWebsocketInternal({ baseUrl: url, application, token, identity, logger });
    return <GaiaContext.Provider value={webSocketObject}>{children}</GaiaContext.Provider>;
}

export function useGaiaWebsocket() {
    const context = useContext(GaiaContext);
    return context;
}
