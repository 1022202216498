import { Flex, Space, Text } from "@mantine/core";
import classes from "../../styles.module.css";
import { feedbackUIMappingFreeText } from "../../types";
import { FeedbackSuccessAnimation } from "./FeedbackSuccessAnimation";

export function FeedbackSuccess() {
    return (
        <Flex direction="column">
            <Text size="xs" className={classes.subtitle} data-testid="feedback-submit-confirmation">
                {feedbackUIMappingFreeText.success.subtitle}
            </Text>
            <Space h="3rem" />
            <FeedbackSuccessAnimation />
        </Flex>
    );
}
