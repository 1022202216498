import type { Logger } from "@expert/logging";
import { useFetchMessageHistoryEvents } from "@soluto-private/expert-workspace-timeline";
import { useGaiaMessageEvents } from "./useGaiaMessageEvents";
import { useSolveSession } from "./useSolveSession";

export function useTimelineSubscriptions(logger: Logger) {
    useGaiaMessageEvents();
    useFetchMessageHistoryEvents(logger);
    useSolveSession();
}
