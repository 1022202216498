import React, { useRef, useMemo } from "react";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { useSetDraggableBounds } from "@soluto-private/expert-workspace-notepad";
import { type ContextProps } from "@expert/features";
import type { Logger } from "@expert/logging";
import { Timeline } from "@soluto-private/expert-workspace-timeline";
import { useExpertAssistStore, useLoggerWithContextStore } from "../../state";
import { useTimelineSubscriptions } from "../../initializers";
import { envName, timelineFeatures } from "../../utils";

function SetupSubscriptions({ loggerWithContext }: { loggerWithContext: Logger }): React.JSX.Element {
    useTimelineSubscriptions(loggerWithContext);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
}
export function StandaloneTimeline() {
    const { callSid, partner, expertProfile, sessionId, lob } = useExpertAssistStore();
    const { loggerWithContext } = useLoggerWithContextStore();
    const containerRef = useRef<HTMLDivElement>(null);
    useSetDraggableBounds(containerRef);

    const featuresContext = useMemo(() => {
        return {
            appName: "expert-assist-standalone",
            employeeId: expertProfile.id,
            environment: envName,
            lob,
            partner,
            source: "expert-assist",
        } as ContextProps;
    }, [expertProfile.id, lob, partner]);

    return (
        <AnalyticsProvider
            dispatcher={(dispatcher) =>
                dispatcher
                    .withIdentities({
                        ...(callSid && { CallSid: callSid }),
                        ...(expertProfile.id && { ExpertId: expertProfile.id }),
                        ...(sessionId && { SessionId: sessionId }),
                    })
                    .withExtra({ tool: "expert-assist", partner, lob })
            }
        >
            <Timeline
                ref={containerRef}
                callSid={callSid}
                defaultFeatures={timelineFeatures}
                expertId={expertProfile.id}
                expertName={expertProfile.firstName || expertProfile.lastName}
                featuresContext={featuresContext}
                logger={loggerWithContext}
                partner={partner}
                sessionId={sessionId}
                SubscriptionsWithFeatures={<SetupSubscriptions loggerWithContext={loggerWithContext} />}
            />
        </AnalyticsProvider>
    );
}
