import { Button, useMantineTheme } from "@mantine/core";
import type { Logger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { type SyntheticEvent, useMemo } from "react";
import classes from "./FeedbackButton.module.css";

interface FeedbackButtonProps {
    logger: Logger;
    openModal: () => void;
    source: string;
}

export function FeedbackButton({ openModal, source, logger: loggerProp }: FeedbackButtonProps) {
    const { dispatcher } = useReactAnalytics();
    const theme = useMantineTheme();
    const logger = useMemo(() => loggerProp.child({ module: "FeedbackButton" }), [loggerProp]);

    const handleClick = (event: SyntheticEvent) => {
        event.preventDefault();
        void dispatcher.dispatchAction("Click", "ShareFeedback", { source });
        logger.info("Feedback modal opened");
        openModal();
    };

    return (
        <Button
            className={classes.button}
            data-testid="feedback-button"
            type="button"
            onClick={handleClick}
            size="xs"
            color={theme.colors.indigo[7]}
        >
            Feedback
        </Button>
    );
}
