import { Flex, Radio } from "@mantine/core";
import { useFeedbackDraftStore } from "../../store";

interface FeedbackRadioGroupProps {
    categories: readonly string[];
    direction: "row" | "column";
}

export function FeedbackRadioGroup({ categories, direction }: FeedbackRadioGroupProps) {
    const { draft, setDraft } = useFeedbackDraftStore();

    const onChangeRadio = (value: string) => {
        setDraft({
            ...draft,
            category: value,
        });
    };

    return (
        <Radio.Group value={draft.category} onChange={onChangeRadio}>
            <Flex direction={direction} gap="lg">
                {categories.map((label) => (
                    <Radio
                        size="sm"
                        key={label}
                        value={label}
                        label={label}
                        data-testid={`radio-feedback-${label}`}
                        fw={label === draft.category ? 700 : 400}
                    />
                ))}
            </Flex>
        </Radio.Group>
    );
}
