import { subscribeToExpert } from "../graphql";
import { useExpertAssistStore } from "../state";

export const initEaStoreSubscriptions = () => {
    // After login, subscribe to expert updates from AppSync
    useExpertAssistStore.subscribe(
        (state) => [state.expertProfile.id, state.token, state.expertProfile.lastName],
        ([expertId, token, lastName]) => {
            if ((expertId ?? lastName) && token) {
                void subscribeToExpert(expertId ?? "");
            }
        },
        {
            fireImmediately: true,
        },
    );
};
