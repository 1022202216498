import { Button } from "@mantine/core";

export function QuickActionButton({ value, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    return (
        <Button
            value={value}
            aria-label={value as string}
            c="var(--mantine-color-white)"
            data-testid={`QuickAction-${value as string}`}
            variant="default"
            vars={() => {
                return {
                    root: {
                        "--button-bd": "unset",
                        "--button-bg": "var(--mantine-color-dark-5)",
                        "--button-height": "var(--button-height-sm)",
                        "--button-hover": "var(--mantine-color-dark-6)",
                    },
                };
            }}
            {...rest}
        >
            {value}
        </Button>
    );
}
