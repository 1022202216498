import { useViewportSize } from "@mantine/hooks";
import { useEffect } from "react";
import { useExpertNoteStore } from "../notepadStore";

const refToCoordinates = (ref: React.MutableRefObject<HTMLElement | null>) => {
    if (!ref.current) return null;
    const rect = ref.current.getBoundingClientRect();
    const coordinates = { x1: rect.x, y1: rect.y, x2: rect.x + rect.width, y2: rect.y + rect.height };
    return coordinates;
};

export const useSetDraggableBounds = (ref: React.MutableRefObject<HTMLElement | null>) => {
    const { height, width } = useViewportSize();

    useEffect(() => {
        const coordinates = refToCoordinates(ref);
        if (coordinates === null) return;

        useExpertNoteStore.getState().setDraggableBounds(coordinates);
    }, [ref, height, width]);

    return null;
};
