import { notifications } from "@mantine/notifications";
import { reactAnalytics } from "@soluto-private/eventualize-react";
import { useFeedbackDraftStore } from "@soluto-private/expert-workspace-feedback";
import { showErrorNotification } from "../errors";
import { CheckIcon } from "./checkIcon";

const showFeedbackSavedNotification = () => {
    const message = "Feedback saved";

    const id = notifications.show({
        title: " ",
        message,
        color: "dark.8",
        icon: <CheckIcon />,
        withCloseButton: true,
        autoClose: 3000,
    });

    void reactAnalytics.dispatcher.dispatchUserAction("Notification_View", "NotificationsSystem", {
        notificationType: "Success",
        notificationSubType: "FeedbackSaved",
        notificationId: id,
        notificationText: message,
    });
};
export type FeedbackNotificationType = "success" | "error";

export const useFeedbackNotification = (): ((type: FeedbackNotificationType) => void) => {
    const { draft } = useFeedbackDraftStore();

    return (type: FeedbackNotificationType) => {
        if (draft.description && type === "success") showFeedbackSavedNotification();
        else if (type === "error") showErrorNotification("FeedbackSavedError");
    };
};
