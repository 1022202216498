import { useEffect } from "react";
import { styled } from "styled-components";
import { RootAnalyticsProvider } from "@soluto-private/eventualize-react";
import { GaiaWebsocketProvider } from "@expert/gaia";
import { SidebarFeedbackButton, Header, PageContainer, ReusableMainContainer, StandaloneTimeline } from "../components";
import { useInitializers } from "../initializers";
import { useLoggerWithContextStore, useExpertAssistStore } from "../state";
import { env } from "../utils/env";

const appContainerHeight = "100vh";
const MainContainer = styled(ReusableMainContainer)`
    margin: auto;
    height: ${appContainerHeight};
    overflow: hidden;
`;

export function Home() {
    useInitializers();
    const { loggerWithContext } = useLoggerWithContextStore();
    const { token, expertProfile } = useExpertAssistStore();

    useEffect(() => {
        loggerWithContext.info({ module: "Home" }, "Home page loaded");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RootAnalyticsProvider>
            <GaiaWebsocketProvider
                logger={loggerWithContext}
                identity={expertProfile.id}
                application="expert-workspace" // TODO: update this to expert-assist once supported by gaia
                token={token ?? ""}
                url={env.gaiaWebsocketUrl}
            >
                <PageContainer>
                    <Header />
                    <MainContainer direction="column" $justify="flex-end">
                        <StandaloneTimeline />
                    </MainContainer>
                    <SidebarFeedbackButton />
                </PageContainer>
            </GaiaWebsocketProvider>
        </RootAnalyticsProvider>
    );
}
