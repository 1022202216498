export function TickIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} fill="none">
            <g clipPath="url(#a)">
                <rect width={23} height={23} y={0.389} fill="#933ADC" rx={11.5} ry={11.5} />
                <path
                    fill="#D5D6DA"
                    fillRule="evenodd"
                    d="M15.3 8.947a.468.468 0 0 1 0 .661l-4.678 4.678a.468.468 0 0 1-.662 0l-2.339-2.339a.468.468 0 0 1 .662-.661l2.008 2.008 4.347-4.347a.468.468 0 0 1 .662 0Z"
                    clipRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <rect width={23} height={23} y={0.389} fill="#fff" rx={11.5} ry={11.5} />
                </clipPath>
            </defs>
        </svg>
    );
}
