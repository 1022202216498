import { Container } from "@mantine/core";
import { useExpertAssistStore } from "../../state";
import { lobs, partners } from "./configValues";
import { RadioGroup } from "./RadioGroup";

export function DevToolsMenuItem() {
    const { callSid, lob, partner, setSessionDetails } = useExpertAssistStore();

    const isDisabled = !!callSid;

    const resetSession = ({ newPartner, newLob }: { newPartner?: string; newLob?: string }) => {
        const sessionId = crypto.randomUUID();
        setSessionDetails({ sessionId, callSid, partner: newPartner ?? partner, lob: newLob ?? lob });
    };

    return (
        <Container>
            <RadioGroup
                label="Line of business"
                options={lobs}
                isDisabled={isDisabled}
                currentSelectedOption={lob}
                onChange={(newLob) => {
                    resetSession({ newLob });
                }}
            />
            <RadioGroup
                label="Partner"
                options={partners}
                isDisabled={isDisabled}
                currentSelectedOption={partner}
                onChange={(newPartner) => {
                    resetSession({ newPartner });
                }}
            />
        </Container>
    );
}
