import { truncateAnalyticsText } from "@soluto-private/eventualize-react";
import { useExpertNoteStore } from "@soluto-private/expert-workspace-notepad";
import { removePii } from "@soluto-private/expert-workspace-pii";
import {
    addMessage,
    formatTimestamp,
    getDefaultMessage,
    solveSessionClosed,
    solveSessionStarted,
    fetchMessageHistory,
} from "@soluto-private/expert-workspace-timeline";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import { useGaiaWebsocket, type GaiaSubscription, type GaiaWebsocketContext } from "@expert/gaia";
import { retryAsync } from "ts-retry";
import { usePrevious } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { sendBusinessEvent } from "../analytics";
import { getEaLogger, useExpertAssistStore } from "../state";

interface SessionLifecycleProps {
    features: Pick<
        TimelineFeatures,
        | "isMultiModalEnabled"
        | "isOzmoMessagingEnabled"
        | "isProactiveEnabled"
        | "isReactiveCallContextEnabled"
        | "isSalesFAQEnabled"
    >;
    sessionId: string;
    websocketObj: GaiaWebsocketContext;
    withCustomer: boolean;
    partner: string;
    callSid?: string;
}

type SubscribeProps = {
    websocketObj: GaiaWebsocketContext;
} & Omit<GaiaSubscription, "sendJsonMessage" | "timeout" | "logger">;

const subscriptionTimeoutDuration = 2000;
const subscriptionRetryCount = 3;

export const useSolveSession = () => {
    const { sessionId, callSid, partner } = useExpertAssistStore();
    const { features } = useFeatures<TimelineFeatures>();
    const websocketObj = useGaiaWebsocket();

    const prevSessionId = usePrevious(sessionId);
    const previousCallSid = usePrevious(callSid);
    const previousWSLoading = usePrevious(websocketObj?.loading);
    const [currentSolveSessionId, setCurrentSolveSessionId] = useState("");

    useEffect(() => {
        if (!sessionId || !websocketObj || websocketObj.loading) {
            return;
        }

        const sessionIdChanged = prevSessionId !== sessionId;
        const websocketObjJustLoaded = previousWSLoading === true;
        const withCustomer = !!callSid;

        if (sessionIdChanged && !withCustomer) {
            addMessage({
                ...getDefaultMessage("offCall"),
                id: `offCall_${crypto.randomUUID()}`,
                timestamp: formatTimestamp(),
                isUnread: true,
            });
        }

        if (sessionIdChanged && prevSessionId) {
            closeSession({ sessionId: prevSessionId, callSid: previousCallSid, websocketObj, partner, features });
            setCurrentSolveSessionId(sessionId);
            void startSession({ sessionId, callSid, partner, websocketObj, withCustomer: !!callSid, features });
            return;
        }

        if (websocketObjJustLoaded || (sessionIdChanged && !prevSessionId)) {
            if (currentSolveSessionId === sessionId) {
                void subscribeSessionToGaia({ sessionId, websocketObj, callSid, partner });
            } else {
                setCurrentSolveSessionId(sessionId);
                void startSession({
                    partner,
                    sessionId,
                    websocketObj,
                    callSid,
                    withCustomer,
                    features,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callSid, sessionId, websocketObj?.loading]);
};

const startSession = async ({
    sessionId,
    websocketObj,
    withCustomer,
    callSid,
    partner,
    features,
}: SessionLifecycleProps) => {
    const loggerWithContext = getEaLogger();
    loggerWithContext.info({ module: "useSolveSession" }, "Expert Assist Session | Started");
    solveSessionStarted({ sessionId, callSid, withCustomer, partner, features });
    await subscribeSessionToGaia({ sessionId, websocketObj, callSid, partner });
    fetchMessageHistory({
        sessionId,
        sendJsonMessage: websocketObj.sendJsonMessage,
        partner,
        features,
    });
};

const subscribeSessionToGaia = async ({ sessionId, callSid, partner, websocketObj }: SubscribeProps) => {
    const loggerWithContext = getEaLogger();
    try {
        await retryAsync(
            async () => {
                await websocketObj.subscribeSessionToGaia({
                    sessionId,
                    callSid,
                    partner,
                    sendJsonMessage: websocketObj.sendJsonMessage,
                    timeout: subscriptionTimeoutDuration,
                    logger: loggerWithContext,
                });
            },
            { delay: subscriptionTimeoutDuration, maxTry: subscriptionRetryCount },
        );
    } catch (err) {
        loggerWithContext.error(
            { err, module: "useSolveSession" },
            `Error subscribing session ${sessionId} to gaia with max retries (${subscriptionRetryCount})`,
        );
    }
};

const closeSession = ({
    callSid,
    features,
    partner,
    sessionId,
    websocketObj,
}: Omit<SessionLifecycleProps, "withCustomer">) => {
    const loggerWithContext = getEaLogger();
    const { note } = useExpertNoteStore.getState();
    if (note) {
        void removePii(note).then((piiScrubbedNote) => {
            sendBusinessEvent({
                eventName: "SaveExpertNote",
                identities: { SessionId: sessionId },
                meta: { SkipMixpanel: true },
                attributes: { note: truncateAnalyticsText(piiScrubbedNote) },
            });
        });
    }
    loggerWithContext.info({ module: "useSolveSession" }, "Expert Assist Session | Closed");
    solveSessionClosed({ sessionId, callSid, partner, features });
    websocketObj.unsubscribeSessionFromGaia({
        sessionId,
        sendJsonMessage: websocketObj.sendJsonMessage,
        logger: loggerWithContext,
    });
};
