import {
    defaultAutoCompleteDelay,
    defaultHelpMeNowButtonText,
    defaultHelpMeNowQuestion,
    type TimelineFeatures,
} from "@expert/features";
import { env } from "./env";

export const envName = env.envName;
export const isProd = env.isProd;

export const timelineFeatures: TimelineFeatures = {
    autoCompleteDelay: defaultAutoCompleteDelay,
    helpMeNowButtonText: defaultHelpMeNowButtonText,
    helpMeNowQuestion: defaultHelpMeNowQuestion,
    isAutoCompleteEnabled: false,
    isHelpMeNowEnabled: false,
    isMessageRatingEnabled: false,
    isMultiModalEnabled: false,
    isOzmoMessagingEnabled: !isProd,
    isOzmoToolEnabled: !isProd,
    isProactiveEnabled: false,
    isReactiveCallContextEnabled: false,
    isSalesFAQEnabled: false,
};
