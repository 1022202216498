import { type Client, generateClient } from "@aws-amplify/api";
import { Amplify } from "@aws-amplify/core";
import { getToken } from "../state";
import { env } from "../utils/env";

Amplify.configure({
    API: {
        GraphQL: {
            endpoint: env.appsyncGraphqlUrl,
            region: env.appsyncRegion,
            defaultAuthMode: "lambda",
        },
    },
});

export const getClient = (): Client => {
    const authToken = getToken();
    if (!authToken) {
        throw new Error("GraphQL can’t be created due to empty token");
    }

    //wow BIG yikes to whoever typed this
    //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    return generateClient<never>({
        authMode: "lambda",
        authToken,
    });
};
