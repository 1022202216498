import { useEffect } from "react";
import {
    setFullStoryIdentifier,
    setDynatraceIdentifier,
    setAppcuesIdentifier,
    trackAppcuesEvent,
} from "@expert/monitoring";
import { useExpertAssistStore } from "../state";
import { useIsTabActive } from "../utils/useIsTabActive";
import { initEaStoreSubscriptions } from "./initEaStoreSubscriptions";
import { initLoggerStoreSubscriptions } from "./initLoggerStoreSubscriptions";

export function useInitializers() {
    const { expertProfile } = useExpertAssistStore();

    useIsTabActive();

    useEffect(() => {
        initEaStoreSubscriptions();
        initLoggerStoreSubscriptions();
    }, []);

    useEffect(() => {
        setFullStoryIdentifier({
            identity: expertProfile.id,
            displayName: expertProfile.email,
            properties: {
                name: `${expertProfile.firstName} ${expertProfile.lastName}`,
                email: expertProfile.email,
                employeeId: expertProfile.id,
            },
        });

        setDynatraceIdentifier({
            name: `${expertProfile.firstName} ${expertProfile.lastName}`,
            email: expertProfile.email,
            employeeId: expertProfile.id,
        });

        setAppcuesIdentifier({
            identity: expertProfile.id,
            name: `${expertProfile.firstName} ${expertProfile.lastName}`,
            email: expertProfile.email,
        });

        if (expertProfile.id) {
            trackAppcuesEvent("Workspace Loaded", {});
        }
    }, [expertProfile]);
}
