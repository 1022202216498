import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { ExternalDevice } from "@expert/shared-types";

export interface DeviceInfoStore {
    devices: ExternalDevice[];
    setDevices: (devices: ExternalDevice[]) => void;
}

export const useDeviceInfoStore = create<DeviceInfoStore>()(
    devtools(
        (set) => ({
            devices: [],
            setDevices: (devices: ExternalDevice[]) =>
                set(
                    {
                        devices,
                    },
                    false,
                    "setDevices",
                ),
        }),
        { enabled: import.meta.env.MODE !== "production", store: "ozmo", name: "workspace/ozmo" },
    ),
);
