import React, { useState } from "react";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { TickIcon } from "../../assets";
import { type ExpertAssistMessage } from "../../shared-types";
import FeedbackIcons from "./FeedbackIcons";
import classes from "./Feedback.module.css";

interface FeedbackProps {
    message: ExpertAssistMessage | undefined;
}
export function Feedback({ message }: FeedbackProps) {
    const [isFeedbackClicked, setIsFeedbackClicked] = useState(false);

    const handleFeedbackClick = () => {
        setIsFeedbackClicked(true);
    };

    return (
        <>
            {isFeedbackClicked ? (
                <Flex className={classes.animate}>
                    <Text size="14px" pr="8px">
                        Thanks for your feedback!
                    </Text>
                    <TickIcon />
                </Flex>
            ) : (
                <Flex className={classes.wrapper}>
                    <ActionIcon variant="transparent" w="2rem" h="2rem">
                        <FeedbackIcons onFeedbackClick={handleFeedbackClick} animationType="up" message={message} />
                    </ActionIcon>
                    <ActionIcon variant="transparent" w="2rem" h="2rem">
                        <FeedbackIcons onFeedbackClick={handleFeedbackClick} animationType="down" message={message} />
                    </ActionIcon>
                </Flex>
            )}
        </>
    );
}
export default Feedback;
