import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface Draft {
    category: string;
    subcategories?: string[];
    description: string;
}
interface FeedbackDraft {
    draft: Draft;
    setDraft: (draft: Draft) => void;
    resetDraft: () => void;
}

export const useFeedbackDraftStore = create<FeedbackDraft>()(
    devtools(
        immer((set) => ({
            draft: {
                category: "",
                description: "",
            },
            setDraft: ({ category, subcategories, description }) => {
                set(
                    ({ draft }) => {
                        draft.category = category;
                        draft.subcategories = subcategories;
                        draft.description = description;
                    },
                    false,
                    "setDraft",
                );
            },
            resetDraft: () => {
                set(
                    ({ draft }) => {
                        draft.category = "";
                        draft.description = "";
                        draft.subcategories = undefined;
                    },
                    false,
                    "resetDraft",
                );
            },
        })),
    ),
);
