import { Checkbox, Radio, Stack, Text } from "@mantine/core";
import Markdown from "markdown-to-jsx";
import { feedbackUIMappingNoText } from "../../types";
import { useFeedbackDraftStore } from "../../store";

export function NoTextFeedback({ isLoading }: { isLoading: boolean }) {
    const { draft, setDraft } = useFeedbackDraftStore();
    const { subtitle, categories, subcategories } = feedbackUIMappingNoText;

    const onChangeCategory = (value: string) => {
        setDraft({
            ...draft,
            category: value,
            subcategories: undefined,
        });
    };

    const onChangeSubcategories = (value: string[]) => {
        const description = value.join(", ");
        setDraft({
            ...draft,
            description,
            subcategories: value,
        });
    };

    return (
        <Stack>
            <Text size="sm" c="dark.3" mb="lg">
                <Markdown options={{ forceInline: true }}>{subtitle}</Markdown>
            </Text>
            <Radio.Group value={draft.category} onChange={onChangeCategory}>
                <Stack gap="lg">
                    {categories.map((category) => (
                        <Stack gap="0.5rem" key={category}>
                            <Radio
                                size="sm"
                                value={category}
                                label={category}
                                data-testid={`radio-feedback-${category}`}
                                disabled={isLoading}
                                fw={category === draft.category ? 700 : 400}
                            />
                            {category === draft.category ? (
                                <Checkbox.Group value={draft.subcategories} onChange={onChangeSubcategories}>
                                    {subcategories[category].map((option) => (
                                        <Checkbox
                                            value={option}
                                            label={option}
                                            key={option}
                                            p="0.5rem"
                                            pl="2rem"
                                            data-testid={`checkbox-feedback-${option}`}
                                            disabled={isLoading}
                                        />
                                    ))}
                                </Checkbox.Group>
                            ) : null}
                        </Stack>
                    ))}
                </Stack>
            </Radio.Group>
        </Stack>
    );
}
