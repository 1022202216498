import { FeedbackPortal } from "@soluto-private/expert-workspace-feedback";
import { useLoggerWithContextStore } from "../../state";
import { useFeedbackNotification } from "./useFeedbackNotification";
import { useFeedbackPayload } from "./useFeedbackPayload";

export function SidebarFeedbackButton() {
    const payload = useFeedbackPayload();
    const triggerNotification = useFeedbackNotification();
    const { loggerWithContext } = useLoggerWithContextStore();

    return (
        <FeedbackPortal
            payload={{
                ...payload,
                caller: "StandaloneTimeline",
            }}
            showNotification={triggerNotification}
            source="Sidebar"
            logger={loggerWithContext}
        />
    );
}
