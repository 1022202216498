export interface SolveFeatures {
    autoCompleteDelay: number;
    isAutoCompleteEnabled: boolean;
    isHelpMeNowEnabled: boolean;
    isConversationalIVRGuideEnabled: boolean;
    isDeviceActivationGuideEnabled: boolean;
    isDeviceReplacementInfoEnabled: boolean;
    isMessageRatingEnabled: boolean;
    isMultiModalEnabled: boolean;
    isOzmoMessagingEnabled: boolean;
    isOzmoToolEnabled: boolean;
    isProactiveEnabled: boolean;
    isReactiveCallContextEnabled: boolean;
    isSalesFAQEnabled: boolean;
}

export interface TimelineFeatures {
    autoCompleteDelay: number;
    helpMeNowButtonText: string;
    helpMeNowQuestion: string;
    isAutoCompleteEnabled: boolean;
    isHelpMeNowEnabled: boolean;
    isMessageRatingEnabled: boolean;
    isMultiModalEnabled: boolean;
    isOzmoMessagingEnabled: boolean;
    isOzmoToolEnabled: boolean;
    isProactiveEnabled: boolean;
    isReactiveCallContextEnabled: boolean;
    isSalesFAQEnabled: boolean;
}

export const defaultAutoCompleteDelay = 500;
export const defaultHelpMeNowButtonText = "Instant Assist";
export const defaultHelpMeNowQuestion = "Help the customer with their technical problem";

export type WorkspaceFeatures = Pick<
    SolveFeatures,
    | "isConversationalIVRGuideEnabled"
    | "isDeviceActivationGuideEnabled"
    | "isDeviceReplacementInfoEnabled"
    | "isProactiveEnabled"
>;

export type TimelineContextType = Record<keyof TimelineFeatures, boolean | number | string>;
export type WorkspaceContextType = Record<keyof WorkspaceFeatures, boolean>;

// group name used for analytics
export type Group = "Timeline" | "Workspace";
