import { reactAnalytics, truncateAnalyticsText } from "@soluto-private/eventualize-react";
import type { FeedbackPayload } from "./types";

export const sendFeedback = async (
    expertFeedbackUrl: string,
    { ssoAccessToken, partner, ...payload }: Omit<FeedbackPayload, "caller">,
) => {
    await reactAnalytics.dispatcher.dispatchBusinessEvent("SubmitFeedback", {
        feedbackCategory: payload.category,
        feedbackText: truncateAnalyticsText(payload.text), // TODO: https://solutonashville.atlassian.net/browse/EWS-933
    });
    await fetch(expertFeedbackUrl, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${ssoAccessToken}`,
        },
        body: JSON.stringify({ ...payload, extraData: { Partner: partner, ...payload.extraData } }),
    });
};
