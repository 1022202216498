import { create } from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { Logger } from "@expert/logging";
import type { ExpertAssistMessage } from "../shared-types";
import { fallbackDefaultMessages } from "./fallbackDefaultMessages";

export interface TimelineStore {
    callSid?: string;
    setCallSid: (callSid?: string, logger?: Logger) => void;

    expertId: string;
    setExpertId: (expertId: string, logger: Logger) => void;

    expertName: string;
    setExpertName: (expertName: string) => void;

    defaultMessages: ExpertAssistMessage[];
    setDefaultMessages: (defaultMessages: ExpertAssistMessage[]) => void;

    messages: ExpertAssistMessage[];
    addMessage: (message: ExpertAssistMessage) => void;
    addMessages: (messages: ExpertAssistMessage[]) => void;
    resetMessages: (messages: ExpertAssistMessage[]) => void;

    lob?: string;
    setLob: (lob?: string) => void;

    partner: string;
    setPartner: (partner: string, logger: Logger) => void;

    sessionId: string;
    setSessionId: (sessionId: string, logger: Logger) => void;

    setIsMessageUnread: (messageId: string, isUnread: boolean) => void;

    shouldAutoOpenToolbox: boolean;
    setShouldAutoOpenToolbox: (val: boolean) => void;

    autoCompleteSuggestions: string[];
    setNewSuggestions: (suggestions: string[]) => void;
}

export const useTimelineStore = create<TimelineStore>()(
    subscribeWithSelector(
        immer(
            devtools(
                persist(
                    (set) => ({
                        defaultMessages: fallbackDefaultMessages,
                        setDefaultMessages: (defaultMessages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.defaultMessages = defaultMessages;
                                },
                                false,
                                "setDefaultMessages",
                            );
                        },
                        messages: [],
                        addMessage: (message: ExpertAssistMessage) => {
                            set(
                                (state) => {
                                    state.messages = [...state.messages, message];
                                },
                                false,
                                "addMessage",
                            );
                        },
                        addMessages: (messages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.messages = [...state.messages, ...messages];
                                },
                                false,
                                "addMessages",
                            );
                        },
                        resetMessages: (messages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.messages = [...messages];
                                },
                                false,
                                "resetMessages",
                            );
                        },
                        expertId: "",
                        setExpertId: (expertId: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ expertId }, "Timeline Setup | expertId");
                                    state.expertId = expertId;
                                },
                                false,
                                "setExpertId",
                            );
                        },
                        expertName: "",
                        setExpertName: (expertName: string) => {
                            set(
                                (state) => {
                                    console.info("Expert Assist Timeline Setup |", `expertName: ${expertName}`);
                                    state.expertName = expertName;
                                },
                                false,
                                "setExpertId",
                            );
                        },
                        callSid: undefined,
                        setCallSid: (callSid?: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ callSid }, "Expert Assist Timeline Setup | callSid");
                                    state.callSid = callSid;
                                },
                                false,
                                "setCallSid",
                            );
                        },
                        lob: undefined,
                        setLob: (lob?: string) => {
                            set(
                                (state) => {
                                    state.lob = lob;
                                },
                                false,
                                "setLob",
                            );
                        },
                        partner: "",
                        setPartner: (partner: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ partner }, "Expert Assist Timeline Setup | partner");
                                    state.partner = partner;
                                },
                                false,
                                "setPartner",
                            );
                        },
                        sessionId: "",
                        setSessionId: (sessionId: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ sessionId }, "Expert Assist Timeline Setup | sessionId");
                                    state.sessionId = sessionId;
                                },
                                false,
                                "setSessionId",
                            );
                        },
                        setIsMessageUnread: (messageId: string, isUnread: boolean) => {
                            set(
                                (state) => {
                                    state.messages = state.messages.map((message) =>
                                        message.id === messageId ? { ...message, isUnread } : message,
                                    );
                                },
                                false,
                                "setIsMessageUnread",
                            );
                        },
                        shouldAutoOpenToolbox: true,
                        setShouldAutoOpenToolbox: (val: boolean) => {
                            set((state) => {
                                state.shouldAutoOpenToolbox = val;
                            });
                        },
                        autoCompleteSuggestions: [],
                        setNewSuggestions: (values: string[]) => {
                            set(
                                (state) => {
                                    state.autoCompleteSuggestions = [...values];
                                },
                                false,
                                "setNewSuggestions",
                            );
                        },
                    }),

                    {
                        name: "timelineStore",
                        partialize: (state: TimelineStore) => ({
                            shouldAutoOpenToolbox: state.shouldAutoOpenToolbox,
                        }),
                    },
                ),
                { enabled: import.meta.env.MODE !== "production", store: "expert-assist", name: "timeline" },
            ),
        ),
    ),
);
