import { createContext, useContext } from "react";

export interface FeaturesContextValue<T> {
    features: T;
}

const FeaturesContext = createContext<FeaturesContextValue<unknown> | undefined>(undefined);

export function FeaturesProvider<T>({ children, ...value }: React.PropsWithChildren<FeaturesContextValue<T>>) {
    return <FeaturesContext.Provider value={value}>{children}</FeaturesContext.Provider>;
}

export function useFeatures<T>(): FeaturesContextValue<T> {
    const result = useContext(FeaturesContext) as FeaturesContextValue<T> | undefined;
    if (result === undefined) throw new Error("Hook used outside of the FeaturesProvider");
    return result;
}
