import { type Logger, getLogger } from "@expert/logging";
import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

interface LoggerWithContext {
    loggerWithContext: Logger;
    setLoggerInstance: (logger: Logger) => void;
}

export const useLoggerWithContextStore = create<LoggerWithContext>()(
    subscribeWithSelector(
        immer(
            devtools(
                (set) => ({
                    loggerWithContext: getLogger({
                        rootModule: "loggerStore",
                        supportTeam: "solve",
                    }),
                    setLoggerInstance: (logger) => {
                        set(
                            () => ({
                                loggerWithContext: logger,
                            }),
                            true,
                            "setLoggerInstance",
                        );
                    },
                }),
                {
                    name: "LoggerStore",
                },
            ),
        ),
    ),
);

export const getEaLogger = () => useLoggerWithContextStore.getState().loggerWithContext;
