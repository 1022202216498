import { Modal, Stack } from "@mantine/core";
import type { Logger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { getFullStoryUrl, trackFullStoryEvent } from "@expert/monitoring";
import { useFeedbackContext } from "../../context";
import { sendFeedback } from "../../sendFeedback";
import { useFeedbackDraftStore } from "../../store";
import classes from "../../styles.module.css";
import type { FeedbackType, PartialFeedbackPayload } from "../../types";
import { getTitle, partnerFeedbackConfig } from "../../types";
import { FeedbackSuccess } from "../success-animation";
import { FreeTextFeedback } from "./FreeTextFeedback";
import { NoTextFeedback } from "./NoTextFeedback";
import { FeedbackActions } from "./FeedbackActions";

interface FeedbackModalProps {
    isOpen: boolean;
    logger: Logger;
    onSubmit?: VoidFunction;
    onClose: VoidFunction;
    onError: VoidFunction;
    feedbackPayload: PartialFeedbackPayload;
}
const TRANSITION_DURATION = 300; // TODO: Import this from theme constants when ea standalone moves over

export function FeedbackModal({
    isOpen = false,
    logger: loggerProp,
    onClose: onCloseProp,
    onSubmit: onSubmitProp,
    onError,
    feedbackPayload,
}: FeedbackModalProps) {
    const { partner } = feedbackPayload;
    const { expertFeedbackUrl } = useFeedbackContext();
    const { resetDraft, draft } = useFeedbackDraftStore();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const timeoutRef = useRef<number | undefined>(undefined);
    const { dispatcher } = useReactAnalytics();
    const logger = loggerProp.child({ module: "Feedback" });

    const feedbackType: FeedbackType = useMemo(
        () => partnerFeedbackConfig[partner] ?? partnerFeedbackConfig.default,
        [partner],
    );

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const fsUrl = await getFullStoryUrl();
            await sendFeedback(expertFeedbackUrl, {
                ...feedbackPayload,
                extraData: { ...feedbackPayload.extraData, "Fullstory URL": fsUrl },
                text: draft.description, // TODO: figure out how to handle this naming mismatch (https://solutonashville.atlassian.net/browse/EWS-933)
                category: draft.category,
            });
            setIsLoading(false);
            onSubmitProp?.();
            resetDraft();
            logger.info("Feedback submitted");
            trackFullStoryEvent("FeedbackSubmitted", {});
            setIsSuccess(true);
        } catch (error) {
            logger.error(error, "Error sending feedback");
            setIsLoading(false);
            onError();
        }
    };

    const onClear = () => {
        resetDraft();
        void dispatcher.dispatchAction("Click", "ClearFeedback", {});
        logger.info("Feedback cleared");
    };

    const onClose = () => {
        void dispatcher.dispatchAction("Click", "CloseFeedback", {});
        if (draft.description) {
            void dispatcher.dispatchAction("Click", "SaveFeedback", {});
            logger.info("Feedback saved");
        }
        onCloseProp();
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setIsSuccess(false);
        }, TRANSITION_DURATION) as unknown as number;
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    let FeedbackComponent;
    switch (feedbackType) {
        case "FreeTextFeedback":
            FeedbackComponent = <FreeTextFeedback isLoading={isLoading} />;
            break;
        case "NoTextFeedback":
            FeedbackComponent = <NoTextFeedback isLoading={isLoading} />;
            break;
    }

    return (
        <Modal
            zIndex="var(--mantine-priority-highest)"
            opened={isOpen}
            onClose={onClose}
            title={getTitle(isSuccess, feedbackType)}
            size="36rem"
            centered
            className={classes.modal}
            styles={{ content: { height: "auto" } }}
        >
            {isSuccess ? (
                <FeedbackSuccess />
            ) : (
                <Stack>
                    {FeedbackComponent}
                    <FeedbackActions onClear={onClear} onSubmit={onSubmit} isLoading={isLoading} />
                </Stack>
            )}
        </Modal>
    );
}
