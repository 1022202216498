import { Button, Group } from "@mantine/core";
import classes from "../../styles.module.css";
import { useFeedbackDraftStore } from "../../store";

interface FeedbackActionProps {
    isLoading: boolean;
    onSubmit: VoidFunction;
    onClear: VoidFunction;
}
export function FeedbackActions({ isLoading, onSubmit, onClear }: FeedbackActionProps) {
    const { draft } = useFeedbackDraftStore();
    const shouldHideClearButton = !draft.description && !draft.category;
    return (
        <Group justify="flex-end" gap="sm">
            {!shouldHideClearButton && (
                <Button
                    className={classes.clearButton}
                    variant="subtle"
                    color="white"
                    size="compact-lg"
                    td="underline"
                    onClick={onClear}
                    disabled={isLoading}
                    data-testid="clear-feedback-button"
                >
                    Clear
                </Button>
            )}
            <Button
                variant="filled"
                color="primary.5"
                size="compact-lg"
                onClick={onSubmit}
                disabled={!draft.description || !draft.category || isLoading}
                data-testid="submit-feedback-button"
                loading={isLoading}
            >
                Submit
            </Button>
        </Group>
    );
}
