import { Flex, Pill } from "@mantine/core";
import type { MessageType } from "../../shared-types";
import type { MessageTagProps } from "./types";
import classes from "./TimelineItem.module.css";

export function TimelineItemWrapper({
    isFromAi,
    children,
}: {
    isFromAi: boolean;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <Flex
            align={isFromAi ? "flex-start" : "flex-end"}
            className={classes.timelineItemWrapper}
            justify={isFromAi ? "flex-start" : "flex-end"}
        >
            {children}
        </Flex>
    );
}

// container that holds the message bubble and any buttons that are supposed to be displayed below it
export function MessageBubbleWrapper({
    isBotListening,
    type,
    children,
}: {
    isBotListening: boolean;
    isExpanded: boolean;
    type: MessageType;
    children: React.ReactNode;
}): JSX.Element {
    const messageWrapperClass = classes.messageBubbleWrapper;
    const botListeningClass = isBotListening ? `${messageWrapperClass} ${classes.botListening}` : messageWrapperClass;
    const className =
        type === "SessionSummary" ? `${botListeningClass} ${classes.sessionSummaryContainer}` : botListeningClass;

    return (
        <Flex className={className} direction="column">
            {children}
        </Flex>
    );
}

export function ProactiveTextMask({
    children,
    isExpanded,
}: {
    isExpanded: boolean;
    children: React.ReactNode;
}): JSX.Element {
    const className = isExpanded ? classes.textMaskExpanded : classes.textMaskNotExpanded;

    return (
        <Flex className={className} direction="column">
            {children}
        </Flex>
    );
}

export function MessageTag({ tagName }: MessageTagProps): JSX.Element {
    return (
        <Pill
            bg="linear-gradient(90deg, var(--mantine-color-success-4) 0.3%, #60FFFF 105.93%)"
            c="var(--mantine-color-black)"
            fw={700}
            fz={12}
            mb=".5rem"
            p="0 .1875rem"
            radius={2}
            size="xs"
            w="fit-content"
        >
            {tagName}
        </Pill>
    );
}
