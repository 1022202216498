import { type TimelineFeatures, useFeatures } from "@expert/features";
import { useGaiaWebsocket } from "@expert/gaia";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { type Logger } from "@expert/logging";
import { useTimelineStore } from "../state";

export function useRequestAutoCompleteSuggestions(logger: Logger) {
    const websocketObj = useGaiaWebsocket();
    const { dispatcher } = useReactAnalytics();
    const { callSid, sessionId } = useTimelineStore();
    const {
        features: { isAutoCompleteEnabled },
    } = useFeatures<TimelineFeatures>();

    if (!isAutoCompleteEnabled || !callSid || !sessionId) {
        return {};
    }

    const requestAutoCompleteSuggestions = (textInput: string) => {
        const fetchAutoCompleteSuggestionsPayload = {
            action: "fetch-auto-complete-suggestion",
            callSid,
            sessionId: callSid,
            sessionGroupId: sessionId,
            timestamp: Date.now(),
            textInput,
        };
        logger
            .child({ module: "useRequestAutoCompleteSuggestions" })
            .info({ input: textInput }, "AutoComplete | suggestion requested");
        void dispatcher.dispatchBusinessEvent("AutoComplete_SuggestionRequested", { input: textInput });

        websocketObj?.sendJsonMessage(fetchAutoCompleteSuggestionsPayload);
    };

    return { requestAutoCompleteSuggestions };
}
